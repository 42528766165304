import React from "react";

// Components
import Heading from "../../../../Components/Heading/Heading";
import Wrapper from "../../../../Components/Wrapper/Wrapper";
import HowItWorksCard from "../HowItWorksCard/HowItWorksCard";
import { Container } from "@hybris-software/ui-kit";


// Icons
import signUp from "../../../../Assets/icons/signUp.svg";
import safe from "../../../../Assets/icons/safe.svg";
import convert from "../../../../Assets/icons/convert.svg";
import withdrawal from "../../../../Assets/icons/withdrawal.svg";

// Styles
import Style from "./HowItWorks.module.css";

const data = [
  {
    image: signUp,
    title: "Sign Up",
    subTitle:
      "Begin by creating an account with PAYZEN Finance. It is quick, simple, and secure.",
  },
  {
    image: safe,
    title: "Deposit FIAT Currency",
    subTitle: "Transfer your USD/EUR to your PAYZEN account.",
  },
  {
    image: convert,
    title: "Convert USD/EUR to USDT",
    subTitle:
      "Use our platform to convert your USD/EUR to USDT or EUR at competitive rates.",
  },
  {
    image: withdrawal,
    title: "Withdraw",
    subTitle:
      "Withdraw your USDT or USD/EUR directly to your desired wallet or bank account.",
  },
];

const HowItWorks = () => {
  return (
    <Container>
      <Wrapper>
        <Heading heading={"The Process"} title={"How it Works"} />

        <div className={Style.cards}>
          {data.map((item, index) => {
            return <HowItWorksCard key={index} index={index} {...item} />;
          })}
        </div>
      </Wrapper>
    </Container>
  );
};

export default HowItWorks;
