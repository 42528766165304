import React from "react";

// Components
import Heading from "../../../../Components/Heading/Heading";
import ServiceCard from "../ServiceCard/ServiceCard";
import Wrapper from "../../../../Components/Wrapper/Wrapper";
import { Container } from "@hybris-software/ui-kit";


// Images
import rightImage from "../../../../Assets/images/rightServiceImage.png";
import leftImage from "../../../../Assets/images/serviceLeft.png";

//Styles
import Style from "./Services.module.css";

// Data
const data = [
  {
    image: leftImage,
    title: "Fiat to USDT Conversion",
    subTitle:
      "We offer a seamless and secure platform for converting your Fiat currencies (USD/EUR) into USDT widely-used stable coin tied to the value of the United States Dollar.",
  },
  {
    image: rightImage,
    title: "Stablecoin to USD/EUR Conversion",
    subTitle:
      "PAYZEN FINANCE provides a robust and efficient platform for converting your stablecoins into traditional fiat currencies like USD and EUR. Our platform ensures quick conversions at competitive rates, making it easier for you to move between the crypto and traditional finance world.",
  },
];

const Services = () => {
  return (
    <Container>
      {/* <Wrapper> */}

        <Heading heading={"What we offer"} title={"Our Services"} />
        <div className={Style.cards}>
          {data.map((item, index) => {
            return <ServiceCard key={index} {...item} />;
          })}
        </div>
      {/* </Wrapper> */}
  </Container>
  );
};

export default Services;
