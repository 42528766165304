import React, { useState } from "react";

// Libraries
import { useLocation, useNavigate } from "react-router-dom";
import { Container, useWindowSize } from "@hybris-software/ui-kit";
import { Link } from "react-router-dom";

// Components
import Wrapper from "../Wrapper/Wrapper";

// Routes
import { paths } from "../../Routes/routes";

// Icons

// Images
import Logo from "../../Assets/icons/navBarLogo.png";

// Styles
import Style from "./Navbar.module.css";
import { BsArrow90DegDown, BsBook } from "react-icons/bs";
import CustomButton from "../CustomButton/CustomButton";

const Navbar = () => {
  const { width } = useWindowSize();
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const hash = location.hash.substring(1);

  if (width > 991) if (menuOpen) setMenuOpen(false);

  if (menuOpen) document.body.style.overflow = "hidden";
  else document.body.style.overflow = "unset";

  return (
    <Container>
      <Wrapper>
        <header className={Style.header}>
          <div className={width > 991 ? Style.navbar : Style.navbarMobile}>
            <Link to="/">
              <img className={Style.logo} src={Logo} alt="Logo" />
            </Link>
            <nav
              className={
                width > 991
                  ? Style.navigator
                  : menuOpen
                  ? Style.navigator
                  : Style.navigatorClose
              }
            >
              <ul className={Style.menuList}>
                <li>
                  <a href="#home">
                    <Link
                      onClick={() => {
                        setMenuOpen((old) => !old);
                        document.documentElement.scrollTop = 0;
                      }}
                      to="/"
                      className={hash === "" ? Style.pageActive : ""}
                    >
                      Home
                    </Link>
                  </a>
                </li>
                <li
                  onClick={() => {
                    setMenuOpen((old) => !old);
                    document.documentElement.scrollTop = 0;
                  }}
                >
                  <a
                    className={hash === "about" ? Style.pageActive : ""}
                    href="#about"
                  >
                    About us
                  </a>
                </li>

                <li
                  onClick={() => {
                    setMenuOpen((old) => !old);
                    document.documentElement.scrollTop = 0;
                  }}
                >
                  <a
                    className={hash === "services" ? Style.pageActive : ""}
                    href="#services"
                  >
                    Services
                  </a>
                </li>
                <li
                  onClick={() => {
                    setMenuOpen((old) => !old);
                    document.documentElement.scrollTop = 0;
                  }}
                >
                  <a
                    className={hash === "howItWorks" ? Style.pageActive : ""}
                    href="#howItWorks"
                  >
                    How It Works
                  </a>
                </li>
                <li
                  onClick={() => {
                    setMenuOpen((old) => !old);
                    document.documentElement.scrollTop = 0;
                  }}
                >
                  <a
                    className={hash === "faqs" ? Style.pageActive : ""}
                    href="#faqs"
                  >
                    FAQs
                  </a>
                </li>
                <li>
                  <a
                    // style={{
                    //   display: width < 991 ? "none" : "block",
                    // }}
                    href="#contactUs"
                  >
                    <div className={Style.customButtonStyle}>Contact Us</div>
                  </a>
                </li>
              </ul>
            </nav>

            <div
              className={menuOpen ? Style.hamburgerActive : Style.hamburger}
              onClick={() => {
                setMenuOpen((old) => !old);
                document.documentElement.scrollTop = 0;
              }}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </header>
      </Wrapper>
    </Container>
  );
};

export default Navbar;
