import React from "react";

// Styles
import Style from "./Heading.module.css";

const Heading = ({ heading, title, subTitle, left }) => {
  return (
    <div
      className={Style.heading}
      style={{
        textAlign: left ? "left" : "center",
        marginBottom: left ? "0px" : subTitle ? "45px" : "80px",
      }}
    >
      <p>{heading}</p>
      <h2>{title}</h2>
      <h6>{subTitle}</h6>
    </div>
  );
};

export default Heading;
