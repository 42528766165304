import React from "react";

// Components
import HeroSection from "./Components/HeroSection/HeroSection";
import Services from "./Components/Services/Services";
import HowItWorks from "./Components/HowItWorks/HowItWorks";
import AboutUs from "./Components/AboutUs/AboutUs";
import Contact from "./Components/Contact/Contact";
import Faqs from "../../Components/Faqs/Faqs";
import GoTop from "../../Components/GoTop/GoTop";

// Styles
import Style from "./HomeView.module.css";

const HomeView = () => {
  return (
    <div 
    className={Style.home}>
      <section id="home">
        <HeroSection />
      </section>
      <section id="about">
        <AboutUs />
      </section>
      <section id="services">
        <Services />
      </section>
      <section id="howItWorks">
        <HowItWorks />
      </section>
      <section id="faqs">
        <Faqs />
      </section>
      <section id="contactUs">
        <Contact />
      </section>
      <GoTop />
    </div>
  );
};

export default HomeView;
