import React from "react";

// Components
import Wrapper from "../../Components/Wrapper/Wrapper";

// Libraries
import { Link } from "react-router-dom";

// Icons
import { AiOutlineArrowLeft } from "react-icons/ai";
// import logo from "../../Assets/icons/logo.svg";

// Style
import Style from "./NotFoundView.module.css";

const NotFoundView = () => {
  return (
    <Wrapper>
      <div className={Style.topOf}>
        <Link to="/">
          {/* <img src={logo} alt="logo" /> */}
        </Link>
        <Link className={Style.backBtn} to="/">
          <AiOutlineArrowLeft />
          <p>Go TO MAIN PAGE</p>
        </Link>
      </div>
      <div className={Style.notFound}>
        <h1>ERROR 404</h1>
        <h3>Page not found</h3>
      </div>
    </Wrapper>
  );
};

export default NotFoundView;
