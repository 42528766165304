import React from "react";

// Styles
import Style from "./ServiceCard.module.css";

const ServiceCard = ({ image, title, subTitle }) => {
  return (
    <div className={Style.card}>
      <div
        className={Style.picture}
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
      <h4>{title}</h4>
      <h6>{subTitle}</h6>
    </div>
  );
};

export default ServiceCard;
