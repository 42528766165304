import React , {useState , useEffect} from 'react'
// Icon
import {BsArrowUp} from "react-icons/bs"
// Style
import Style from './GoTop.module.css'
const GoTop = () => {

    const [show, setShow] = useState(false)
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 300) {
                setShow(true)
            } else {
                setShow(false)
            }
        })
    }, [])
  return (
    <div className={Style.wrapper}>
        {show? (
        <a href="#home" className={Style.topWrapper}>
            <div className={Style.goTopIcon}>
                <BsArrowUp className={Style.icon}/>
            </div>
        </a>
        ):""}
    </div>
  )
}

export default GoTop