import React from "react";
// Libraries
import { Button, Container } from "@hybris-software/ui-kit";

// Images
import mobile from "../../../../Assets/images/mobile.png";

// Style
import Style from "./HeroSection.module.css";

const HeroSection = () => {
  return (
    <Container>
      <div className={Style.heroSectionWrapper}>
        <div className={Style.heroSection}>
          <div className={Style.textSide}>
            <p className={Style.smalltitle}>
              Welcome to PAYZEN FINANCE, Simplifying 
            </p>
            <p className={Style.title}>Fiat to USDT Conversion</p>
            <p className={Style.content}>
              A licensed money services business dedicated to offering seamless
              conversions from FIAT to crypto (USDT). Our state-of-the-art
              platform provides secure, fast, and reliable digital currency
              conversion from crypto (USDT) to FIAT.
            </p>
            <a href="#contactUs">
              <Button className={Style.button}>Contact Us</Button>
            </a>
          </div>
          <div className={Style.imageSide}>
            <img src={mobile} alt="" className={Style.image} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HeroSection;
