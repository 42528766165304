import React from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";
import { useLocation } from "react-router-dom";

// Logo
import logoWhite from "../../Assets/icons/footerLogo.png";

// Styles
import Style from "./Footer.module.css";
import privacy from "../../Assets/data/privacy.pdf";
import terms from "../../Assets/data/terms.pdf";
import complaintsPolicy from "../../Assets/data/complaintsPolicy.pdf";

const Footer = () => {
  const location = useLocation();
  const hash = location.hash.substring(1);
  return (
    <div className={Style.footer}>
      <Container>
        <div className={Style.top}>
          <div className={Style.company}>
            <img
              onClick={() => {
                document.documentElement.scrollTop = 0;
              }}
              src={logoWhite}
              alt=""
            />
            <p>PAYZEN FINANCE Inc</p>
            <a
              target="_blank"
              href="https://www10.fintrac-canafe.gc.ca/msb-esm/public/detailed-information/msb-details/7b226d73624f72674e756d626572223a3136373030302c227072696d617279536561726368223a7b226f72674e616d65223a227061797a656e222c2273656172636854797065223a317d7d/"
            >
              CRN: BC1345446
            </a>
            <p>MSB License N: M22235907</p>
            <p>422 Richards St, Suite 170, Vancouver, BC V6B 2Z4 </p>
            <p>Canada</p>
          </div>
          <div className={Style.siteMap}>
            <a href="#home">Home</a>
            <a href="#about"> About us</a>
            <a href="#services"> Services</a>
            <a href="#howItWorks"> How It Works</a>
            <a href="#faqs"> FAQs</a>
            <a href="#contactUs"> Contact Us</a>
          </div>
        </div>
        <div className={Style.separator} />
        <div className={Style.bottom}>
          <a href={terms} target="_blank" rel="noreferrer">
            Terms & Conditions
          </a>
          <a href={privacy} target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          {/* <a href={complaintsPolicy} target="_blank" rel="noreferrer">
            Complaints Policy
          </a> */}

          <p>© 2023 PAYZEN</p>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
