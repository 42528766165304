import React from "react";
import ReactDOM from "react-dom/client";
import "../src/Assets/css/index.css";
import { generateApiClient, ApiProvider } from "@hybris-software/use-query";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
const apiClient = generateApiClient({
  baseUrl: "https://api.payzen.finance",
  authorizationHeader: "Authorization",
  authorizationPrefix: "Bearer",
});
root.render(
  <React.StrictMode>
    <ApiProvider apiClient={apiClient}>
      <App />
    </ApiProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
