import React from "react";

// Icons
import lineArrow from "../../../../Assets/icons/lineArrow.svg";

// Styles
import Style from "./HowItWorksCard.module.css";

const HowItWorksCard = ({ image, title, subTitle, index }) => {
  return (
    <div className={Style.card}>
      <img src={image} alt="" />
      {index !== 3 && <img src={lineArrow} alt="" />}
      <h6>{title}</h6>
      <h6>{subTitle}</h6>
    </div>
  );
};

export default HowItWorksCard;
