import React from 'react'
// Libraries
import { Button, InputField, TextField , Container } from "@hybris-software/ui-kit";
import useForm from "@hybris-software/use-ful-form";
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";
// Style
import Style from "./Contact.module.css"
import Heading from '../../../../Components/Heading/Heading';
const Contact = () => {

    const contactForm = useForm({
        inputs: {
          firstName: {
            required: true,
          },
          lastName: {
            required: true,
          },
          email: {
            required: true,
            nature: "email",
          },
          phoneNumber: {
            required: true,
            formatter: (value) => value.replace(/\D/g, ""),

          },
          message: {
            required: true,
          },
        },
      });

      // Queries
        // Queries
  const contactFormApi = useQuery({
    url: "/api/v1/forms/contact/",
    method: "POST",
    onSuccess: () => {
      contactForm.reset();
      alert("Your message has been sent!");
    },
  });

  const formMessage = contactFormApi.isSuccess
    ? "Your message has been sent successfully!"
    : contactFormApi.isError && "Something went wrong, Please try again later!";

  const submitFormHandler = () => {
    contactForm.validate();
    contactForm.isValid() &&
      contactFormApi.executeQuery(contactForm.getApiBody());
  };

    
  return (
        <div className={Style.contactWrapper}>
   <Container>
            <Heading subTitle={"If you have any questions, or  concerns, or need assistance with your transactions, please do not hesitate to contact us."} heading={"Contact Us"} title={"We are here to help!"} />
            <div className={Style.contact}>
                <div className={Style.contactFormNames}>
                    <InputField
                        className={Style.feild}
                        placeholder="First name*"
                        {...contactForm.getInputProps("firstName")}
                    />
                    <InputField
                        className={Style.feild}
                        placeholder="Last name*"
                        {...contactForm.getInputProps("lastName")}
                    />
                </div>
                <div className={Style.contactFormInfo}>
                    <InputField
                        className={Style.feild}
                        placeholder="Email id*"
                        {...contactForm.getInputProps("email")}
                    />
                    <InputField
                        className={Style.feild}
                        placeholder="Phone Number*"
                        {...contactForm.getInputProps("phoneNumber")}
                        />
                </div>
                    <TextField
                        className={Style.textFieldStyle}
                        placeholder="Message*"
                        rows={8}
                        {...contactForm.getInputProps("message")}
                    />
                    <div className={Style.buttonWrapper}>

                      <Button
                          className={Style.button}
                          isLoading={contactFormApi.isLoading}
                          onClick={submitFormHandler}
                          >
                          Send Message
                      </Button>
                    </div>
        </div>
        <p className={Style.formMsg}>{formMessage}</p>
      </Container>
        </div>

    
  )
}

export default Contact

