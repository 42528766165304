import React, { useState } from "react";

// Components
import Wrapper from "../../Components/Wrapper/Wrapper";

// icons
import { BsPlus } from "react-icons/bs";

// Styles
import Style from "./Faqs.module.css";
import Heading from "../Heading/Heading";

const data = [
  {
    title: "How does PAYZEN convert fiat to USDT?",
    text: "PAYZEN uses a seamless and secure platform for converting your fiat currencies (USD) into USDT. Once you deposit your USD into your PAYZEN account, you can easily convert it into USDT at competitive rates. The conversion process is automated and typically completes within a few minutes.",
  },
  {
    title: "How can I convert my stablecoins to USD or EUR using PAYZEN?",
    text: "PAYZEN provides a straightforward process for converting stablecoins to USD or EUR. You simply deposit your stablecoins into your PAYZEN account, then choose the option to convert them into the fiat currency of your choice. The conversion is processed quickly, and the funds will be available in your account shortly.",
  },
  {
    title: "What are the fees for converting currencies on PAYZEN?",
    text: "PAYZEN strives to offer competitive rates for currency conversion. The exact fees depend on the specific currencies being converted and the current market conditions. You can view our current fees on the PAYZEN platform.",
  },
  {
    title: "How does PAYZEN ensure the security of my funds during the conversion process?",
    text: "PAYZEN takes security very seriously. We use advanced security measures to protect your funds and personal information. This includes encryption, two-factor authentication, and regular security audits. We also comply with all relevant regulations to ensure the safety and security of your funds.",
  },
  {
    title: "What stablecoins does PAYZEN support for conversion to fiat currencies?",
    text: "PAYZEN currently supports USDT for conversion to USD and EUR. We are continuously working to add support for more stablecoins based on the needs and demands of our users. Please check our platform for the most up-to-date information on supported currencies.",
  },
];

const Faqs = () => {
  return (
    <Wrapper>
      <Heading
        heading={"Frequently asked questions"}
        title={"Happy to answer your questions"}
      />
      <div className={Style.accordionGrid}>
        {data?.map((item, index) => {
          return <Accordion key={index} title={item.title} text={item.text} />;
        })}
      </div>
    </Wrapper>
  );
};

const Accordion = ({ title, text }) => {
  const [active, setActive] = useState(true);
  return (
    <div className={Style.wrapper}>
      <div
        className={Style.headingContainer}
        onClick={() => setActive(!active)}
      >
        <h4>{title}</h4>
        <div className={active ? Style.iconOpen : Style.iconClosed}>
          <BsPlus />
        </div>
      </div>

      <h6 className={active ? Style.activeText : Style.text}>{text}</h6>
    </div>
  );
};
export default Faqs;
