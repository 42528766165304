import React from 'react'
// Component
import Heading from "../../../../Components/Heading/Heading"
// Style
import Style from "./AboutUs.module.css"
const AboutUs = () => {
  return (
    <div className={Style.aboutUsWrapper}>
        <div className={Style.aboutUs}>
            <div className={Style.titleWrap}>
                <p className={Style.heading}>
                    About US
                </p>
                <p className={Style.title}>
                    PAYZEN FINANCE
                </p>
            </div>
            <div className={Style.content}>
                <p className={Style.contentOne}>
                    PAYZEN FINANCE is a globally recognized company specializing in digital currency conversion. As a licensed money services business (MSB), we are committed to providing our users with secure, straightforward, and efficient services. 
                </p>
                <p className={Style.contentTwo}>
                    Our primary operation revolves around Fiat to USDT conversion, helping our clients bridge the gap between traditional and digital currencies.
                </p>
            </div>
        </div>
    </div>
  )
}

export default AboutUs